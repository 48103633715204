import {
  AdminInfo,
  AuthResponse,
  UserCompleteProfile,
  UserResponse,
  UserSearchList,
  UserStatus,
} from "@/types";
import { ApiService, AxiosHeaders } from "@/services";
import { AxiosResponse } from "axios";
import { SuperAdminUser } from "@/types";

const users = "users";

class UserService {
  async getUserById(id: number): Promise<any> {
    return ApiService.api.get(`${users}/id/${id}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async updateProfile(input: AdminInfo): Promise<AdminInfo> {
    const { userId, ...body } = input;

    return ApiService.api.put(`${users}/profile`, body, {
      params: { userId },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async deleteUser(
    id: number
  ): Promise<AxiosResponse<{ result: { userId: number; deleted: boolean } }>> {
    return ApiService.api.delete(`${users}/delete/${id}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async toggleUserActivation(
    userId: string,
    action: UserStatus
  ): Promise<AxiosResponse<{ result: SuperAdminUser }>> {
    return ApiService.api.put(
      `${users}/activation/${action}/${userId}`,
      undefined,
      {
        headers: AxiosHeaders.headersWithCredentials(),
      }
    );
  }

  async completeProfile(
    userProfile: UserCompleteProfile
  ): Promise<AxiosResponse<{ result: AuthResponse }>> {
    return ApiService.api.put(`${users}/profile/complete`, userProfile);
  }

  async searchUser(
    offset?: number,
    limit?: number,
    search?: string
  ):Promise<AxiosResponse<{ result: UserSearchList }>>{
    return ApiService.api.get(`${users}/search/all`,{
      headers: AxiosHeaders.headersWithCredentials(),
      params: { offset, limit, search },

    })
  }
}
const singleton = new UserService();
export default singleton;
