import React, { useState } from "react";
import { KeyboardAvoidingView, StatusBar, View } from "react-native";
import {
  colors,
  Modal,
  showFriendlyErrorFromHTMLCode,
  useFieldControl,
} from "@/lib";
import { SideRegistration } from "@/components/Layouts/SideRegistration";
import { useDeviceContext } from "twrnc";
import tw from "@/lib/tailwind";
import { Button, Loading, Text } from "@/components/Commons";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { required, requiredDefault } from "@/lib/Rules";
import { RootState, store } from "@/store";
import { useAppDispatch, useAppSelector } from "@/store/hooks";
import { ErrorFixed, WorkSpace } from "@/types";
import {
  FacebookPixelService,
  FirebaseService,
  PlanService,
  TelephoneService,
  WorkspaceService,
} from "@/services";
import TreeError from "@/components/Tree/NestedListError";
import SweetAlert from "react-bootstrap-sweetalert";
import { useEffect } from "react";
import { Country, State } from "country-state-city";
import { useAlert } from "@/hooks";
import { useMemo } from "react";
import {
  DropDownItem,
  SimpleDropdown,
} from "@/components/Inputs/SimpleDropdown";
import { useNavigate } from "@/routes";
import { ThankYouDialog } from "@/components/Phone/ThankYouDialog";
import {
  BUTTON_PHONE_CREATE_WORKSPACE,
  BUTTON_PHONE_MODAL_OK,
} from "@/services/firebase.service";
import { DEFAULT_EVENT_PURCHASE } from "@/services/facebook.pixel.service";
import { PhoneApiResponse } from "@/types/phone.api.reponse.types";

var levenshtein = require("fast-levenshtein");
var randomstring = require("randomstring");

export const PhoneAccount = () => {
  useDeviceContext(tw);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyInfo, admin } = useAppSelector(
    (state: RootState) => state.register
  );
  const [stateError, setStateArError] = useState<ErrorFixed[] | string>([]);
  //Loading indicator
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modalKey, setModalKey] = useState<string>();
  const { showSwal } = useAlert();

  const generateDomain = (base: string, suffix: boolean) => {
    let limit = 64;
    let suffixLength = 7;
    let minBase = 7;
    const trimBase = base
      .trim()
      .toLocaleLowerCase()
      .replace(/[^0-9a-z]/gi, "");
    const isSuffix = suffix || trimBase.length < minBase;
    if (isSuffix) limit = limit - suffixLength;
    const ending = isSuffix ? randomstring.generate(suffixLength) : "";
    return trimBase.slice(0, limit).concat(ending);
  };

  const submit = async () => {
    createWorkspace(false);
  };

  const createWorkspace = async (replay: boolean) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      FirebaseService.logFirebaseEvent(BUTTON_PHONE_CREATE_WORKSPACE);

      const plan = await PlanService.getPlanBasic();
      const domain = generateDomain(companyInfo.company_name, replay);
      const relam = `${domain}.sip.newtws.com`;

      if (plan !== undefined) {
        const workspace: WorkSpace = {
          workspace: {
            ...companyInfo,
            licenses_purchased: companyInfo.userLicense,

            unit_number: undefined,
            plan: plan.name,
            plan_id: plan?.id,

            domain_name: domain,
            account_name: domain,
            realm: relam,

            did_location: {
              country: phoneInput.country.value,
              region: phoneInput.province_state.value,
              rate_center: phoneInput.city.value,
            },

            ...(phoneInput.area.value != autoAreaCode.value && {
              npa: phoneInput.area.value.split("-")[0],
              nxx: phoneInput.area.value.split("-")[1],
            }),
          },
          admin: {
            ...admin,
          },
        };

        setModalKey(
          Modal.show(<View />, {
            width: 460,
            noClose: true,
            hasOverlay: false,
            modalStyle: tw`lg:ml-[400px] shadow-md`,
            overlayPointerEvents: "none",
            overlayBackgroundColor: "#00000000",
          })
        );

        const result = await WorkspaceService.create(workspace);
        console.log(result);
        if (!result) throw new Error();

        FacebookPixelService.logDefaultEvent(DEFAULT_EVENT_PURCHASE, {
          currency: "USD",
          value: 1.0,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setStateArError("An unexpected error ocurred");
      }
    } catch (err: any) {
      //no loopying
      if (
        false &&
        !replay &&
        err.response &&
        err.response.status === 422 &&
        (err.response.data?.error?.errorJsonObject?.domain ||
          JSON.parse(err.response.data?.error?.errorJsonObject)?.domain ||
          err.response.data?.error?.errorJsonObject === "DOMAIN_EXISTS")
      ) {
        console.log("replay create workspace");
        createWorkspace(true);
      } else {
        Modal.dismissAll();
        setIsLoading(false);
        setError(err);
      }
    }
  };

  const onModalCanel = () => {
    FirebaseService.logFirebaseEvent(BUTTON_PHONE_MODAL_OK);
    Modal.dismissAll();
    navigate("registerSuccess");
  };

  useEffect(() => {
    if (modalKey) {
      Modal.update(modalKey, {
        children: <ThankYouDialog loading={isLoading} onCanel={onModalCanel} />,
      });
    }
  }, [modalKey, isLoading]);

  const registerStore = store.getState();
  const defaultCountry = {
    label: "Please select your Country",
    value: "default",
  };
  const defaultState = {
    label: "Please select your Province/State",
    value: "default",
  };
  const defaultCity = { label: "Please select your City", value: "default" };
  const defaultArea = {
    label: "Please select your Area Code",
    value: "default",
  };
  const autoAreaCode = { label: "Auto-select area code", value: "000-000" };

  const phoneInput = {
    country: useFieldControl<string>(
      registerStore.register.companyInfo.country,
      [required, requiredDefault(defaultCountry.value)]
    ),
    province_state: useFieldControl<string>(
      registerStore.register.companyInfo.province,
      [required, requiredDefault(defaultState.value)]
    ),
    city: useFieldControl<string>(registerStore.register.companyInfo.city, [
      required,
      requiredDefault(defaultCity.value),
    ]),
    area: useFieldControl<string>(defaultArea.value, [
      required,
      requiredDefault(defaultArea.value),
    ]),
  };

  //Telephones load effect
  const [phoneAPi, changePhone] = useState<PhoneApiResponse[]>([]);
  useEffect(() => {
    TelephoneService.checkPhoneRegion()
      .then((data) => {
        //Get the result
        const { result } = data.data;
        changePhone(result);
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  const setError = (error: any) => {
    Modal.dismissAll();
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    setIsLoading(false);
  };

  const countries = useMemo(() => {
    if (phoneAPi.length > 0) {
      const countries = phoneAPi
        .reduce((results: DropDownItem[], item: PhoneApiResponse) => {
          const code = Country.getCountryByCode(item.country);
          if (code) {
            const newItem: DropDownItem = {
              label: code.name,
              value: item.country,
            };
            if (!results.some((item) => item.value === newItem.value)) {
              results.push(newItem);
            }
          }
          return results;
        }, [])
        .sort((a: DropDownItem, b: DropDownItem) =>
          a.label > b.label ? 1 : -1
        );

      const selectedCountry = countries.find((item: DropDownItem) => {
        item.value === phoneInput.country.value;
      });
      if (!selectedCountry) {
        phoneInput.country.handleInputValue("CA");
      }

      return countries;
    }
  }, [phoneAPi]);

  const states = useMemo(() => {
    if (phoneAPi.length > 0) {
      let states = phoneAPi
        .reduce((results: DropDownItem[], item: PhoneApiResponse) => {
          if (item.country == phoneInput.country.value) {
            const label = State.getStateByCodeAndCountry(
              item.region,
              item.country
            )?.name;
            if (label) {
              const newItem: DropDownItem = {
                label: label,
                value: item.region,
              };
              if (!results.some((item) => item.value === newItem.value)) {
                results.push(newItem);
              }
            }
          }
          return results;
        }, [])
        .sort((a: DropDownItem, b: DropDownItem) =>
          a.label > b.label ? 1 : -1
        );

      states = states.sort((a: DropDownItem, b: DropDownItem) =>
        a.label > b.label ? 1 : -1
      );
      states.unshift(defaultState);

      if (states.length > 0) {
        const selectedState = states.find((item: DropDownItem) => {
          return item.value === phoneInput.province_state.value;
        });
        if (!selectedState) {
          phoneInput.province_state.handleInputValue(states[0].value);
        }
      }
      return states;
    }
  }, [phoneAPi, phoneInput.country.value]);

  const cities = useMemo(() => {
    if (phoneAPi.length > 0) {
      let cities = phoneAPi.reduce(
        (results: DropDownItem[], item: PhoneApiResponse) => {
          if (item.region == phoneInput.province_state.value) {
            const newItem: DropDownItem = { label: item.rc, value: item.rc };
            if (!results.some((item) => item.value === newItem.value)) {
              results.push(newItem);
            }
          }
          return results;
        },
        []
      );
      cities = cities.sort((a: DropDownItem, b: DropDownItem) =>
        a.label > b.label ? 1 : -1
      );
      cities.unshift(defaultCity);

      if (cities.length > 0) {
        let selectedCity = cities.find((item: DropDownItem) => {
          return item.value === phoneInput.city.value;
        });
        if (!selectedCity) {
          selectedCity = cities.find((item: DropDownItem) => {
            return item.value.indexOf(phoneInput.city.value) >= 0;
          });
        }
        if (!selectedCity) {
          selectedCity = cities.find((item: DropDownItem) => {
            return levenshtein.get(item.value, phoneInput.city.value) < 2;
          });
        }

        if (!selectedCity) {
          phoneInput.city.handleInputValue(cities[0].value);
        } else {
          phoneInput.city.handleInputValue(selectedCity.value);
        }
      }

      return cities;
    }
  }, [phoneAPi, phoneInput.province_state.value]);

  const areas = useMemo(() => {
    if (phoneAPi.length > 0) {
      let areas = phoneAPi.reduce(
        (results: DropDownItem[], item: PhoneApiResponse) => {
          if (
            item.region == phoneInput.province_state.value &&
            item.rc == phoneInput.city.value
          ) {
            const newItem: DropDownItem = {
              label: item.npa + "-" + item.nxx,
              value: item.npa + "-" + item.nxx,
            };
            if (!results.some((item) => item.value === newItem.value)) {
              results.push(newItem);
            }
          }
          return results;
        },
        []
      );

      areas = areas.sort((a: DropDownItem, b: DropDownItem) =>
        a.label > b.label ? 1 : -1
      );
      areas.unshift(autoAreaCode);
      areas.unshift(defaultArea);

      if (areas.length > 0) {
        const selectedArea = areas.find((item: DropDownItem) => {
          const phoneValue = phoneInput.area.value;
          return item.value === phoneValue;
        });

        if (!selectedArea) {
          phoneInput.area.handleInputValue(defaultArea.value);
        }
      }

      return areas;
    }
  }, [phoneAPi, phoneInput.province_state.value, phoneInput.city.value]);

  const valid =
    phoneInput.country.valid &&
    phoneInput.province_state.valid &&
    phoneInput.city.valid &&
    phoneInput.area.valid;

  return (
    <>
      <KeyboardAvoidingView behavior="padding" style={tw`flex-grow-1`}>
        <StatusBar
          // @ts-ignore
          style="light"
        />
        <View
          style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}]`}
        >
          <SideRegistration step={5} />
          {isLoading ? (
            <View style={[tw`flex-grow-1 w-auto`]}>
              <Loading />
            </View>
          ) : (
            <AuthLayout>
              <View
                style={[
                  tw`flex flex-grow-1 flex-col justify-center  w-full max-w-[330px] items-center`,
                ]}
              >
                <View
                  style={tw`flex flex-col items-center mt-[1rem] md:mt-[5rem]`}
                >
                  <Text type="h2" weight="bold" style={tw`text-center`}>
                    Choose Your Phone Numbers
                  </Text>
                  <View style={[tw` flex  w-full my-8  `]}>
                    <SimpleDropdown
                      label="Country *"
                      value={phoneInput.country.value}
                      errorMessage={phoneInput.country.error}
                      data={countries}
                      onSelect={phoneInput.country.handleInputValue}
                    />
                    <SimpleDropdown
                      label="Province/State *"
                      disabled={!phoneInput.country.valid}
                      value={
                        !phoneInput.country.valid
                          ? defaultState.value
                          : phoneInput.province_state.value
                      }
                      errorMessage={phoneInput.province_state.error}
                      data={states}
                      onSelect={phoneInput.province_state.handleInputValue}
                    />
                    <SimpleDropdown
                      label="City/Region *"
                      disabled={
                        !phoneInput.country.valid ||
                        !phoneInput.province_state.valid
                      }
                      value={
                        !phoneInput.country.valid ||
                        !phoneInput.province_state.valid
                          ? defaultCity.value
                          : phoneInput.city.value
                      }
                      errorMessage={phoneInput.city.error}
                      data={cities}
                      onSelect={phoneInput.city.handleInputValue}
                    />
                    <SimpleDropdown
                      label="Area Code"
                      disabled={
                        !phoneInput.country.valid ||
                        !phoneInput.province_state.valid ||
                        !phoneInput.city.valid
                      }
                      value={
                        !phoneInput.country.valid ||
                        !phoneInput.province_state.valid ||
                        !phoneInput.city.valid
                          ? defaultArea.value
                          : phoneInput.area.value
                      }
                      errorMessage={phoneInput.area.error}
                      data={areas}
                      onSelect={phoneInput.area.handleInputValue}
                    />
                    <Button
                      style={tw`sm:pb-6 pb-2 my-4`}
                      disabled={isLoading || !valid}
                      onPress={submit}
                      loading={isLoading}
                    >
                      <Text>Submit</Text>
                    </Button>
                  </View>
                </View>
              </View>
            </AuthLayout>
          )}
        </View>
        <View style={[tw`flex items-center justify-center`, { zIndex: 20 }]}>
          <View>
            <SweetAlert
              error
              show={stateError.length > 0}
              title={typeof stateError == "string" && stateError}
              onConfirm={() => {
                setStateArError([]);
              }}
            >
              <View style={tw`flex items-start justify-start w-full`}>
                {typeof stateError !== "string" && (
                  <>
                    <View style={tw`flex items-center justify-center ml-8`}>
                      <Text
                        color={colors.dark}
                        type={"bigger"}
                        weight={"semiBold"}
                      >
                        Errors:
                      </Text>
                    </View>
                    <TreeError error={stateError} />
                  </>
                )}
              </View>
            </SweetAlert>
          </View>
        </View>
      </KeyboardAvoidingView>
    </>
  );
};
