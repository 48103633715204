import React, { useState, useEffect } from "react";
import {
  KeyboardAvoidingView,
  StatusBar,
  View,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  TouchableOpacity,
  Linking,
} from "react-native";

import tw from "@/lib/tailwind";
import { useDeviceContext } from "twrnc";
import { AuthLayout } from "@/components/Layouts/AuthLayout";
import { TextInput } from "@/components/Inputs/TextInput";
import { Button, Text } from "@/components/Commons";
import {
  colors,
  Modal,
  showFriendlyErrorFromHTMLCode,
  useFieldControl,
} from "@/lib";
import { mail, required } from "@/lib/Rules";
import { setAccountInfo } from "@/store/register.store";
import { useDispatch } from "react-redux";
import { useNavigate } from "@/routes";
import {
  ApiService,
  ConfigService,
  FirebaseService,
  MailVerification,
} from "@/services";
import Sidelogin from "@/components/Layouts/Sidelogin";
import { useAlert } from "@/hooks";
import { ResponsiveLayout } from "@/components/Layouts/ResponsiveLayout";
import WaitingService from "@/services/waitinglist.service";
import { SendEmail } from "@/types";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ConfigKey } from "@/services/config.service";
import { BUTTON_REGISTRATION_CLICK } from "@/services/firebase.service";

export const Registration = () => {
  useDeviceContext(tw);
  const { showSwal } = useAlert();
  const navigate = useNavigate();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const accountInput = {
    company: useFieldControl<string>("", [required]),
    account: useFieldControl<string>("", [required]),
    email: useFieldControl<string>("", [mail, required]),
  };

  type select = "Join Waitlist" | "Check Out The Features" | "Continue";
  const recaptchaRef = React.useRef<ReCAPTCHA>();
  const [emailValid, changeEmailValid] = useState<boolean | undefined>();
  const [captcha, setCaptcha] = useState<string>(); //response from google
  const [captch_key, setCaptchKey] = useState<string>(); //google site key
  const [statelistwating, setStateWaitListing] = useState<select>("Continue");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const timeout: NodeJS.Timeout = setTimeout(() => {
      checkEmail();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [accountInput.email.value]);

  const loadCaptchKey = async () => {
    const config = await ConfigService.getAll();
    setCaptchKey(config.googleCaptchaSiteKey);
  };
  useEffect(() => {
    loadCaptchKey();
  }, []);
  const valid =
    accountInput.email.valid &&
    emailValid &&
    ((captcha && captcha?.length > 0) || captch_key === undefined);

  const dispatch = useDispatch();
  const sendEmail = async () => {
    if (valid) {
      try {
        setIsLoading(true);
        FirebaseService.logFirebaseEvent(BUTTON_REGISTRATION_CLICK);
        const data = (await ApiService.checkEmail(accountInput.email.value))
          .data.result;
        const validationCheck =
          data.waitingListStatus?.waitlistEmailExists &&
          !data.waitingListStatus?.isApproved;
        const validationJoin =
          data.waitingListStatus &&
          !data.waitingListStatus?.waitlistEmailExists &&
          !data.waitingListStatus?.isApproved;
        if (validationCheck) {
          setStateWaitListing("Check Out The Features");
        } else if (validationJoin) {
          setStateWaitListing("Join Waitlist");
        } else {
          await MailVerification.sendWorkspaceVerifyEmail(
            accountInput.email.value,
            captcha
          );
          dispatch(
            setAccountInfo({
              email: accountInput.email.value,
              account_name: accountInput.email.value.slice(
                accountInput.email.value.indexOf("@") + 1,
                accountInput.email.value.length
              ),
              secret: "",
            })
          );

          navigate("registerMail");
        }

        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setError(e);
      }
    }
  };

  const setError = (error: any) => {
    showSwal({ msg: showFriendlyErrorFromHTMLCode(error), type: "error" });
    setIsLoading(false);
  };
  const invalid = !valid;

  const checkEmail = async () => {
    if (accountInput.email.value) {
      ApiService.checkEmail(accountInput.email.value).then((r) =>
        changeEmailValid(
          r.data.result.coreApiResponse?.available === true &&
            r.data.result.nucleusResponse?.available === true
        )
      );
    }
  };

  const joinWaitingList = async () => {
    const emailSend: SendEmail = {
      email: accountInput.email.value,
    };
    try {
      await WaitingService.addEmail(emailSend);
      Modal.show(
        <View>
          <View style={tw`flex-row justify-center mb-1`}>
            <Text type="h2" weight="bold">
              Thanks For Joining The Waitlist
            </Text>
            <TouchableOpacity onPressOut={Modal.dismiss}></TouchableOpacity>
          </View>

          <View
            style={tw`flex-grow-1 mb-4 w-full items-center justify-center mt-5`}
          >
            <Text>You will receive an email when we're ready</Text>
            <Text>{""} to welcome you to Nucleus.</Text>
          </View>

          <View style={tw`flex flex-row justify-center mt-10`}>
            <View style={tw`flex flex-row w-2/3`}>
              <Button
                style={tw`flex-1`}
                onPressOut={() => {
                  Linking.openURL("https://nucleus.ca/");
                  setStateWaitListing("Check Out The Features");
                  Modal.dismiss();
                }}
              >
                <Text weight="semiBold">OK</Text>
              </Button>
            </View>
          </View>
        </View>,
        { width: 550 }
      );
    } catch (error) {
      setError(error);
    }
  };

  const checkfeature = () => {
    Linking.openURL("https://nucleus.ca");
  };

  const EMAIL_CHECK_INTERVAL = 1000;
  let emailCheckInterval = EMAIL_CHECK_INTERVAL;
  const emailHandleInputValue = (input: string) => {
    accountInput.email.setValue(input);
    if (input != "") {
      setTimeout(() => {
        accountInput.email.validate(input);
        if (accountInput.email.valid) {
          emailCheckInterval = EMAIL_CHECK_INTERVAL;
        } else {
          emailCheckInterval = 0;
        }
      }, emailCheckInterval);
    } else {
      accountInput.email.setError("");
      emailCheckInterval = EMAIL_CHECK_INTERVAL;
    }
  };

  const onCaptchaChange = (value: React.SetStateAction<string | undefined>) => {
    console.log("Captcha value:", value);
    setCaptcha(value);
  };

  const emailError = accountInput.email.error
    ? accountInput.email.error
    : emailValid === false
    ? "Sorry, but this email address is not available"
    : undefined;

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={tw`flex-grow-1 `}
    >
      <StatusBar
        // @ts-ignore
        style="light"
      />
      <TouchableWithoutFeedback
        onPress={Keyboard.dismiss}
        disabled={Platform.OS == "web"}
      >
        <View
          style={tw`flex flex-grow-1 flex-row bg-[${colors.rebrandLayoutBGColor}] `}
        >
          <Sidelogin />
          <AuthLayout alwaysBack={useQuery().get("source") === "login"}>
            <View style={tw`flex sm:hidden my-4`}>
              <ResponsiveLayout />
            </View>
            <View
              style={[
                tw`flex flex-grow-1 flex-col justify-center w-full max-w-[400px] items-center`,
              ]}
            >
              <View style={tw`flex flex-col items-center`}>
                <Text
                  type="h2"
                  weight="bold"
                  style={[
                    tw`font-bold text-center text-[${colors.blackLogin}]`,
                  ]}
                >
                  Step 1 - User Authentication <br /> Enter Email address to
                  begin
                </Text>
              </View>
              <View style={tw`w-[330px] pt-8`}>
                <View style={tw`w-full max-w-[350px] `}>
                  <TextInput
                    label="Email Address"
                    value={accountInput.email.value}
                    errorMessage={emailError}
                    type="email"
                    onChangeText={emailHandleInputValue}
                    onBlur={checkEmail}
                    keyboardType="email-address"
                    autoCapitalize="none"
                  />
                </View>
                {statelistwating === "Join Waitlist" && (
                  <View style={tw`w-[18rem] mx-auto`}>
                    <Text color={colors.rebrandGray} style={tw`text-justify`}>
                      We are currently activating people from our waitlist. It
                      looks like you're not on our waitlist; would you like to
                      join?
                    </Text>
                  </View>
                )}

                {statelistwating === "Check Out The Features" && (
                  <View style={tw`w-[18rem] mx-auto`}>
                    <Text color={colors.rebrandGray} style={tw`text-justify`}>
                      We are currently activating businesses from our waitlist.
                      We have confirmed you are on our waitlist and you will
                      receive an email when we're ready to welcome you to
                      Nucleus.
                    </Text>
                  </View>
                )}

                <View style={tw`flex flex-col items-center`}>
                  {captch_key && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={captch_key}
                      onChange={onCaptchaChange}
                    />
                  )}
                </View>
              </View>

              <View style={tw`flex w-[330px] pt-8 items-center justify-center`}>
                <Button
                  // disabled={invalid || isLoading}
                  onPress={
                    statelistwating === "Join Waitlist"
                      ? joinWaitingList
                      : statelistwating === "Check Out The Features"
                      ? checkfeature
                      : sendEmail
                  }
                  loading={isLoading}
                  disabled={invalid}
                >
                  <Text>{statelistwating}</Text>
                </Button>
              </View>
            </View>
          </AuthLayout>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
};

export const componentColors = {
  password_icon_color: "#9E9E9E",
};
