import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { PhoneApiResponse } from "@/types/phone.api.reponse.types";

const endpoint = "available";

class TelephoneService {
  /**
   * Check the phone
   */
  async checkPhoneRegion(): Promise<
    AxiosResponse<{ result: PhoneApiResponse[] }>
  > {
    return ApiService.api.get(`${endpoint}/didcoverages/porta`);
  }
}

const singleton = new TelephoneService();
export default singleton;
