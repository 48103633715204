import {
  WorkspaceResponse,
  AllWorkspaces,
  WorkspaceId,
} from "@/types";
import { AxiosResponse } from "axios";
import { ApiService } from "./";
import { AxiosHeaders } from "@/services";
import {
  SuperAdminListReponse,
  SuperAdminResponse,
  SuperAdminUser,
} from "@/types/superAdmin.types";

const endpoint = "superadmin";

class SuperAdminService {
  async getWorkspace(
    workspaceId: number
  ): Promise<AxiosResponse<{ result: WorkspaceResponse }>> {
    return ApiService.api.get(`${endpoint}/customer-account/${workspaceId}`, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
  /**
   * List all workspaces on the platform.
   *
   * @returns {AxiosResponse<{result:{ list:AllWorkspaces[] }}
   */
  async getAllWorkspaces(
    offset: number,
    limit: number
  ): Promise<
    AxiosResponse<{ result: { list: AllWorkspaces[]; totalCount: number } }>
  > {
    return await ApiService.api.get(`${endpoint}/customers`, {
      params: { offset, limit },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  /**
   * List all users on the platform.
   *
   * @returns {AxiosResponse<{result:{ list:AllWorkspaces[] }}
   */
  async getWorkspacesDetails(
    workspaceId: number
  ): Promise<AxiosResponse<{ result: { list: AllWorkspaces[] } }>> {
    return ApiService.api.get(`${endpoint}/customer-account`, {
      params: workspaceId,
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  /**
   * Activate a workspace.
   *
   * @returns {AxiosResponse<{result:{ list:any }}
   */

  async activateWorkspace(
    input: WorkspaceId
  ): Promise<AxiosResponse<{ result: any }>> {
    return ApiService.api.post(`${endpoint}/customers/activate`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  /**
   * Suspend a workspace.
   *
   * @returns {AxiosResponse<{result:{ list:any }}
   */

  async suspendWorkspace(
    input: WorkspaceId
  ): Promise<AxiosResponse<{ result: any }>> {
    return ApiService.api.post(`${endpoint}/customers/suspend`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  async deleteWorkspaces(input: WorkspaceId): Promise<AxiosResponse<any>> {
    return ApiService.api.post(`${endpoint}/customers/delete`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  /**
   * Insert new SuperAdmins.
   *
   * @returns {AxiosResponse<any>}
   */

  async insertSuperAdmin(
    input: SuperAdminResponse
  ): Promise<AxiosResponse<any>> {
    return ApiService.api.post(`${endpoint}/create-another-superadmin`, input);
  }

  async getAllSuperAdmin(
    offset?: number,
    limit?: number
  ): Promise<
    AxiosResponse<{
      result: { list: SuperAdminListReponse[]; totalCount: number };
    }>
  > {
    return ApiService.api.get(`${endpoint}`, {
      params: { offset, limit },
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }

  /**
   * Activate and Deactive SuperAdmin
   *
   * @returns {AxiosResponse<any>}
   */

  async activateSuperAdmin(id: number): Promise<AxiosResponse<any>> {
    return ApiService.api.put(``, id);
  }

  /**
   * Delete SuperAdmin
   *
   * @returns {AxiosResponse<any>}
   */

  async deleteSuperadmin(id: number): Promise<AxiosResponse<any>> {
    return ApiService.api.delete(`` + id);
  }

  /**
   * Get all Users of a SuperAdmin
   *
   * @returns {AxiosResponse<any>}
   */

  async getAllUsersWithPagination(
    offset: number,
    limit?: number,
    query?: string
  ): Promise<
    AxiosResponse<{ result: { list: SuperAdminUser[]; totalCount: number } }>
  > {
    return ApiService.api.get("users", {
      headers: AxiosHeaders.headersWithCredentials(),

      params: { offset, limit, query },
    });
  }

  async createSystemUser(
    input: SuperAdminResponse
  ): Promise<AxiosResponse<any>> {
    return ApiService.api.post(`${endpoint}/create-system-user`, input, {
      headers: AxiosHeaders.headersWithCredentials(),
    });
  }
}

const singleton = new SuperAdminService();
export default singleton;
