import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientAdmin, ClientInput } from "@/types";

const initialState: ClientInput = {
  email: "",
  company_name: "",
  account_name: "",
  realm: "",
  city: "",
  province: "",
  postal_code: "",
  unit_number: undefined,
  street_name: "",
  street_number: "",
  country: "Canada",
  plan: "",
  plan_id: "",
  userLicense: 1,
  planPrice: 0,
  phone_number_quantity: 1,
  did_location: {
    country: "",
    region: "",
    rate_center: "",
  },
};

const adminState: ClientAdmin = {
  first_name: "",
  email: "",
  last_name: "",
  password: "",
  extension: "100",
};

/** Create store slice to save register flow info */
const registerSlice = createSlice({
  name: "registerStore",
  initialState: {
    companyInfo: { ...initialState },
    clientId: "",
    admin: { ...adminState },
  },

  reducers: {
    setAccountInfo: (
      state,
      action: PayloadAction<{
        //companyName: string;
        //accountName: string;
        email: string;
        account_name: string;
        secret: string;
      }>
    ) => {
      //here bineath were companyName, accountName
      const { email, account_name, secret } = action.payload;
      return (state = {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          account_name,
          email,
        },
        admin: {
          ...state.admin,
          email,
          secret,
        },
      });
    },
    setAccountDetails: (
      state,
      payload: PayloadAction<{
        company_name: string;
        account_name: string;
        // account_name: string;
        realm?: string;
        city: string;
        province: string;
        postal_code: string;
        unit_number?: string;
        street_name: string;
        street_number: string;
        country: string;
        plan: string;
        phone_number_quantity: number;
        npa?: string;
        nxx?: string;
      }>
    ) => {
      const input = payload.payload;
      return (state = {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          ...input,
        },
      });
    },
    setPlan: (
      state,
      payload: PayloadAction<{
        plan: string;
        userLicense: number;
        planPrice: number;
        planId: string;
      }>
    ) => {
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          plan: payload.payload.plan,
          userLicense: payload.payload.userLicense,
          planPrice: payload.payload.planPrice,
          plan_id: payload.payload.planId,
        },
      };
    },
    setClientId: (state, payload: PayloadAction<string>) => {
      return { ...state, clientId: payload.payload };
    },
    setAdmin: (state, payload: PayloadAction<ClientAdmin>) => {
      const {
        first_name,
        email,
        last_name,
        password,
        phone,
        phoneSecret,
        extension,
      } = payload.payload;
      return {
        ...state,
        admin: {
          ...state.admin,
          first_name,
          email,
          last_name,
          password,
          phone,
          phoneSecret,
          extension,
        },
      };
    },
    resetState: (state) => {
      return {
        companyInfo: { ...initialState },
        clientId: "",
        admin: { ...adminState },
      };
    },
    selectInfo: (state) => state,
  },
});

export default registerSlice.reducer;

export const {
  selectInfo,
  setAccountInfo,
  setAccountDetails,
  setPlan,
  setClientId,
  setAdmin,
  resetState,
} = registerSlice.actions;
